import { Component, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationStart, Router, NavigationEnd } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import * as LiveUpdates from '@capacitor/live-updates';
import { first, map } from 'rxjs';
// import OneSignal from 'onesignal-cordova-plugin';
import { App } from '@capacitor/app';
import { AppStoreFacade } from 'store/app-store-facade/app-store.facade';
import { DevicesFacade } from 'store/devices-store/devices.facade';
import { modalTransition, pageTransition } from './animations';
import { StorageFacade } from 'store/storage-store/storage.facade';
import { ActivationsFacade } from 'store/activations-store/activations.facade';
import { applyNewDesign } from './utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  template: `<ion-app>
    <pro-global-notice
      [isOffline]="isOffline$ | async"
      [activationState]="activationState$ | async"
      [isBluetoothEnabled]="isBluetoothEnabled$ | async"
      [isLocationEnabledAndAvailable]="isLocationEnabledAndAvailable$ | async"
      [hasDevicesConnected]="hasDevicesConnected$ | async"
      [locationPermission]="locationPermission"
      (requestLocationPermission)="requestLocationPermission()"
    ></pro-global-notice>
    <pro-measurement-transfer
      [devices]="devices$ | async"
      [measurementTransferTutorialShownAt]="measurementTransferTutorialShownAt"
      [isDisabled]="appIsDisabled$ | async"
      (setMeasurementTutorialTimestamp)="setMeasurementTutorialTimestamp($event)"
    ></pro-measurement-transfer>

    <ion-router-outlet [animation]="animation$ | async"></ion-router-outlet>
  </ion-app>`,
})
export class AppComponent implements OnInit {
  animation$ = this.appStoreFacade.animation$.pipe(
    map((a) => {
      return a === 'modal' ? modalTransition : pageTransition;
    }),
  );

  appIsDisabled$ = this.appStoreFacade.appIsDisabled$;
  devices$ = this.devicesFacade.devices$;
  measurementTransferTutorialShownAt = '';
  isOffline$ = this.appStoreFacade.isOffline$;
  activationState$ = this.activationsFacade.activationsCurrentState$;
  isBluetoothEnabled$ = this.devicesFacade.isBluetoothEnabled$;
  isLocationEnabledAndAvailable$ = this.devicesFacade.isLocationEnabledAndAvailable$;
  hasDevicesConnected$ = this.devicesFacade.hasDevicesConnected$;
  locationPermission = '';

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private appStoreFacade: AppStoreFacade,
    private devicesFacade: DevicesFacade,
    private storageFacade: StorageFacade,
    private activationsFacade: ActivationsFacade,
  ) {}

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        this.initializeApp();
      }

      if (this.platform.is('android')) {
        // registers a high priority handler for the android back-button
        this.platform.backButton.subscribeWithPriority(666, this.handleAndroidHardwareBackButton);
      }

      this.storageFacade
        .get('measurementTransferTutorialShownAt')
        .then((dateString: string) => (this.measurementTransferTutorialShownAt = dateString));

      // applies the prefered color-sheme
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      this.toggleDarkTheme(prefersDark.matches);
      // listens for changes to the prefers-color-scheme media query
      prefersDark.addEventListener('change', (mediaQuery) => {
        this.toggleDarkTheme(mediaQuery.matches);
      });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          applyNewDesign();
        }
      });
      applyNewDesign();
    });
  }

  initializeApp() {
    SplashScreen.hide();

    if (this.platform.is('ios')) {
      // enable scrolling when keyboard is opened on ios
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }

    // enable live updates, depending on app config auto_update property
    this.appStoreFacade
      .loadAppConfig()
      .pipe(first())
      .subscribe((config) => {
        if (config.auto_update) {
          this.enableLiveUpdates();
        }
      });

    /*  depending on whether the keyboard is open or closed, a css class is appended to the body
        this is used for the styling of the footer when the keyboard is open
      */
    this.platform.keyboardDidShow.subscribe(() => {
      document.body.classList.add('keyboard-open');
    });

    this.platform.keyboardDidHide.subscribe(() => {
      document.body.classList.remove('keyboard-open');
    });

    // OneSignal.setNotificationOpenedHandler((event) => {
    //   this.zone.run(() => {
    //     // must run inside zone when router navigate is invoked on app initialisation
    //     if (
    //       event.notification.additionalData &&
    //       (event.notification.additionalData as Record<string, string>).message_id
    //     ) {
    //       this.router.navigate(['app/message'], {
    //         queryParams: {
    //           id: (event.notification.additionalData as Record<string, string>).message_id,
    //         },
    //       });
    //     } else {
    //       this.router.navigate(['app/overview/inbox']);
    //     }
    //   });
    // });
  }

  handleAndroidHardwareBackButton = (processNextHandler: () => void) => {
    // finds last firstChild in current snapshot
    let snapshot = this.activatedRoute?.snapshot;

    while (snapshot?.firstChild) {
      snapshot = snapshot.firstChild;
    }
    // closes app on back-button click
    if (snapshot?.data?.androidBackButtonCloseApp) {
      App.minimizeApp();
    }
    // executes next registered handler
    if (!snapshot?.data?.androidBackButtonIgnore) {
      processNextHandler();
    }
  };

  private toggleDarkTheme(shouldAdd: boolean) {
    document.body.classList.toggle('dark', shouldAdd);

    if (this.platform.is('android')) {
      StatusBar.setBackgroundColor({ color: shouldAdd === true ? '#000000' : '#ffffff' });
    }

    if (this.platform.is('hybrid')) {
      StatusBar.setStyle({ style: shouldAdd === true ? Style.Dark : Style.Light });
    }
  }

  setMeasurementTutorialTimestamp(dateString: string) {
    this.storageFacade.set('measurementTransferTutorialShownAt', dateString);
    //  updates the measurement-transfer input value
    this.measurementTransferTutorialShownAt = dateString;
  }

  requestLocationPermission() {
    this.devicesFacade
      .requestLocationPermission()
      .then((locationStatus) => (this.locationPermission = locationStatus));
  }

  private async enableLiveUpdates() {
    const result = await LiveUpdates.sync();
    // if active path has changed, then a new live update was downloaded
    if (result.activeApplicationPathChanged) {
      // reload the app, the new version will appear afterward
      await LiveUpdates.reload();
    }
  }
}
