import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { Patient } from 'app/models';
import * as InfoActions from './info.actions';

export const infoFeatureKey = 'info';

export interface State {
  details: Patient;
  loading: boolean;
}

export const initialState: State = {
  details: {
    title: '',
    surname: '',
    firstname: '',
    username: '',
    birthdate: null,
    gender: null,
    weight: '',
    height: '',
    addresses: null,
    phone_numbers: [],
    email: '',
    insurance_company: '',
    insurance_number: '',
    allergies: '',
    blood_group: null,
    implants: '',
    relationship_status: '',
    smoking_type: null,
    diga_warranty_accepted_at: null,
    accepted_anonymized_storage: false,
    accepted_delayed_deletion: false,
    primary_physician: null,
  },
  loading: false,
};

const patientReducer = createReducer<State>(
  initialState,
  on(InfoActions.loadInfo, InfoActions.updateInfo, (state) => ({
    ...state,
    loading: true,
  })),
  on(InfoActions.loadInfoSuccess, InfoActions.updateInfoFailure, (state, props) => ({
    ...state,
    details: props.data,
  })),
  on(InfoActions.updateInfoPartialSuccess, (state, props) => ({
    ...state,
    details: { ...state.details, ...props.updatedData },
  })),
  on(
    InfoActions.loadInfoSuccess,
    InfoActions.updateInfoSuccess,
    InfoActions.updateInfoFailure,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return patientReducer(state, action);
}

export const selectFeature = createFeatureSelector<State>('info');

export const selectLoading = createSelector(selectFeature, (state: State) => state.loading);

export const selectInfo = createSelector(selectFeature, (state: State) => state.details);

export const selectUsername = createSelector(selectInfo, (state) => {
  return state.username !== '' ? `${state.username}` : null;
});

export const selectGender = createSelector(selectInfo, (state) => state.gender);

export const selectUserHasPhoneNumber = createSelector(
  selectFeature,
  (state: State) => !(!state.details.phone_numbers || state.details.phone_numbers.length === 0)
);

export const selectPrimaryPhysician = createSelector(
  selectInfo,
  (state) => state.primary_physician
);

export const selectAcceptedDelayedDeletion = createSelector(
  selectInfo,
  (state) => state.accepted_delayed_deletion
);
