import { Directive, HostListener, Optional, Self, Input } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[proNumberInputWithSuffix]',
})
export class NumberInputWithSuffixDirective {
  @Input('proNumberInputWithSuffix') type: 'integer' | 'float' = 'integer';

  @Input() suffix = '';

  @HostListener('focusin') onFocus() {
    this.placeCursor();
  }

  @HostListener('click') onClick() {
    this.placeCursor();
  }

  @HostListener('ionInput') onIonInput() {
    this.onInput();
  }

  constructor(private element: IonInput, @Self() @Optional() private baseControl: NgControl) {}

  onInput() {
    const formattedValue = this.getCleanedInputValue();
    // prefixes the suffix with a space if a suffix is present
    const adjustedSuffix = this.suffix.trim() ? ` ${this.suffix.trim()}` : '';

    if (formattedValue) {
      this.baseControl.control.setValue(`${formattedValue}${adjustedSuffix}`);
      this.placeCursor();
    } else {
      this.baseControl.reset();
    }
  }

  private getCleanedInputValue() {
    const regex = this.type !== 'float' ? /[^0-9]+/g : /[^0-9.,]+/g;
    const cleanedInputValue: string = this.baseControl?.value?.toString()?.replace(regex, '') || '';
    return cleanedInputValue;
  }

  /**
   *  if the cursor is behind the user input, position
   *  the cursor after the last digit of the user input
   */
  private placeCursor() {
    this.element?.getInputElement()?.then((input) => {
      const inputLength = this.getCleanedInputValue().length;

      if (input?.selectionEnd > inputLength) {
        input.setSelectionRange(inputLength, inputLength);
      }
    });
  }
}
