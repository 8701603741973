import { skipWhile, tap, concatMap, first, filter } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AppStoreFacade } from 'store/app-store-facade/app-store.facade';
import { AuthFacade } from 'store/auth-store/auth.facade';
import { DocumentsFacade } from 'store/documents-store/documents.facade';
import { DevicesFacade } from 'app/app-store/devices-store/devices.facade';
import { DiagnosesFacade } from 'store/diagnoses-store/diagnoses.facade';
import { MonitoringGoalsFacade } from 'store/monitoring-goals-store/monitoring-goals.facade';
import { MedicalContactsFacade } from 'store/medical-contacts-store/medical-contacts.facade';
import { MessagesFacade } from 'store/messages-store/messages.facade';
import { TermsOfServiceFacade } from 'app/app-store/terms-of-service-store/terms-of-service.facade';
import { EnvironmentService } from 'app/services/environment.service';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import * as dayjs from 'dayjs';
import { InfoFacade } from 'store/info-store/info.facade';
import { TelemedicineCentersFacade } from 'store/telemedicine-centers-store/telemedicine-centers.facade';
import { ActivationsFacade } from 'store/activations-store/activations.facade';
import { MeasurementsFacade } from 'store/measurements-store/measurements.facade';
import { ArticlesFacade } from 'store/articles-store/articles.facade';
import { MedicationIntakesFacade } from 'app/app-store/medication-intakes-store/medication-intakes.facade';
import { MedicationsFacade } from 'store/medications-store/medications.facade';
import { DocumentCategoriesFacade } from 'app/app-store/document-categories-store/document-categories.facade';

@Injectable({
  providedIn: 'root',
})
export class AppStoreResolver {
  constructor(
    private appStoreFacade: AppStoreFacade,
    private authFacade: AuthFacade,
    private documentsFacade: DocumentsFacade,
    private documentCategoriesFacade: DocumentCategoriesFacade,
    private monitoringGoalsFacade: MonitoringGoalsFacade,
    private devicesFacade: DevicesFacade,
    private infoFacade: InfoFacade,
    private diagnosesFacade: DiagnosesFacade,
    private telemedicineCentersFacade: TelemedicineCentersFacade,
    private activationsFacade: ActivationsFacade,
    private medicalContactsFacade: MedicalContactsFacade,
    private messagesFacade: MessagesFacade,
    private medicationIntakesFacade: MedicationIntakesFacade,
    private medicationsFacade: MedicationsFacade,
    private termsOfServiceFacade: TermsOfServiceFacade,
    private platform: Platform,
    private zone: NgZone,
    private envService: EnvironmentService,
    private storageFacade: StorageFacade,
    private measurementsFacade: MeasurementsFacade,
    private articlesFacade: ArticlesFacade,
  ) {
    this.platform.pause.subscribe(() => {
      // set current timestamp for re-authentication
      this.storageFacade.set('reAuthentication', dayjs().toString());
    });
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.platform.resume.subscribe(async () => {
      // clear inactivity timeout
      const timestamp = dayjs(await this.storageFacade.get('reAuthentication'));
      const diff = Math.abs(dayjs().diff(timestamp, 'second'));

      // delete auth storage when the user was inactive for 30min
      if (diff >= 1800 && this.envService.environment.reAuthentication) {
        this.authFacade.deleteAuthStorage();
      } else {
        this.zone.run(() => {
          this.initialLoading(true).pipe(first()).subscribe();
        });
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    switch (route.data.type) {
      case 'initApp':
        this.initialLoading().pipe(first()).subscribe();

        if (this.platform.is('hybrid')) {
          this.devicesFacade.loadDevices();
        }
        break;

      case 'loadMessages':
        this.messagesFacade.loadMessages();
        break;

      case 'loadDocuments':
        this.documentCategoriesFacade.loadDocumentCategories();
        break;

      case 'loadDocumentsPages':
        this.documentsFacade.loadDocumentsPages();
        this.documentCategoriesFacade.loadDocumentCategories();
        break;

      case 'loadDocument':
        this.documentsFacade.loadDocumentByRouteId();
        break;

      case 'loadProfile':
        this.infoFacade.loadInfo();
        break;

      case 'loadMedicalContacts':
        this.telemedicineCentersFacade.loadTelemedicineCenters();
        this.medicalContactsFacade.loadMedicalContacts();
        this.infoFacade.loadInfo();
        break;

      case 'loadDiagnoses':
        this.diagnosesFacade.loadDiagnoses();
        break;

      case 'loadMedication':
        this.medicationsFacade.loadMedications();
        break;

      case 'loadCalendar':
        this.documentsFacade.loadDocuments(dayjs().subtract(6, 'week').format('YYYY-MM-DD'));
        this.measurementsFacade.loadMeasurements(dayjs().subtract(6, 'week').format('YYYY-MM-DD'));
        this.medicationIntakesFacade.loadMedicationIntakes(
          dayjs().subtract(6, 'week').format('YYYY-MM-DD'),
        );
        break;

      case 'loadArticles':
        this.articlesFacade.loadArticles();
        break;

      default:
        console.error(new Error(`Type ${route.data.type} does not exist.`));
        break;
    }
  }

  private initialLoading(reload = false) {
    this.infoFacade.loadInfo();
    return this.infoFacade.loading$.pipe(
      filter((l) => !l),
      concatMap(() => {
        this.monitoringGoalsFacade.loadMonitoringGoals();
        return this.monitoringGoalsFacade.loading$.pipe(skipWhile((l) => !l));
      }),
      filter((l) => !l),
      concatMap(() => {
        this.medicationIntakesFacade.loadMedicationIntakes(
          dayjs().subtract(3, 'd').format('YYYY-MM-DD'),
          dayjs().format('YYYY-MM-DD'),
        );
        return this.medicationIntakesFacade.loading$.pipe(skipWhile((l) => !l));
      }),
      filter((l) => !l),
      concatMap(() => {
        this.activationsFacade.loadActivations();
        return this.activationsFacade.activationsLoading$.pipe(skipWhile((l) => !l));
      }),
      filter((l) => !l),
      concatMap(() => {
        this.appStoreFacade.loadFormOptions();
        return this.appStoreFacade.formOptionsLoading$;
      }),
      filter((l) => !l),
      tap(() => {
        this.measurementsFacade.loadMeasurements(dayjs().subtract(10, 'day').format('YYYY-MM-DD'));

        this.medicationsFacade.loadMedications();
        this.termsOfServiceFacade.loadTermsOfService();
        if (!reload) {
          this.messagesFacade.startPollingMessages();
        }
      }),
      first(),
    );
  }
}
